import React from 'react';
import cn from 'classnames';

import { StaticImage } from '../Image';

import styles from './uspBlock.module.scss';

interface USPBlockProps {
  image: string;
  children: React.ReactNode;
  className?: string;
  left?: boolean;
}

export function USPBlock(props: USPBlockProps) {
  return (
    <div
      className={cn(styles.uspSection, props.className, {
        [styles.uspImageLeft]: props.left,
      })}
    >
      <div className={styles.uspContent}>{props.children}</div>
      <StaticImage className={styles.uspImage} loading="lazy" name={props.image} />
    </div>
  );
}
