import React from 'react';
import cn from 'classnames';

import { StaticImage } from '../Image';
import T from '../Translate';

import styles from './pageHero.module.scss';

interface PageHeroProps {
  title: string;
  titleData?: Record<UnsafeAny, UnsafeAny>;
  image?: string;
  children?: React.ReactNode;
  primary?: boolean;
  secondary?: boolean;
  className?: string;
  imageClassName?: string;
}

export function PageHero(props: PageHeroProps) {
  const titleClassName = cn({
    primary: props.primary && !props.secondary,
    secondary: !props.primary && props.secondary,
  });

  return (
    <>
      <div className={cn(styles.hero, props.className)}>
        <T as="h1" className={titleClassName} data={props.titleData} html id={props.title} />
        {props.children}
      </div>
      {props.image ? (
        <div className={cn(styles.heroImage, props.imageClassName)}>
          <StaticImage alt="" name={props.image} />
        </div>
      ) : null}
    </>
  );
}
