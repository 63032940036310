import React from 'react';
import { graphql } from 'gatsby';

import RouteEnum from '../RouteEnum';
import { useRouteContext } from '../RouteProvider';
import { Language } from '../services/Language';
import { StaticImage } from '../views/Image';
import PageLayout from '../views/Layout/Page';
import { PageHero } from '../views/Layout/PageHero';
import { USPBlock } from '../views/Layout/USPBlock';
import MetaTags from '../views/MetaTags';
import { Restriction } from '../views/Restriction';
import T from '../views/Translate';

import styles from './aboutUs.module.scss';

export const query = graphql`
  query {
    members: allTeamJson {
      nodes {
        id
        name
        job
        image
        link
      }
    }
  }
`;

export default function AboutUs({ data }) {
  const { generatePath } = useRouteContext();
  const members = data.members.nodes as {
    id: string;
    name: string;
    job: string;
    image: string;
    link?: string;
  }[];

  return (
    <PageLayout pageview="about_us">
      <MetaTags title="aboutUs.meta.title" />

      <PageHero image="about-us_hero" title="aboutUs.hero.title" />

      <USPBlock image="about-us_usp_1">
        <T as="h2" html id="aboutUs.usp.1.title" />
        <T as="p" html id="aboutUs.usp.1.description" />
      </USPBlock>

      <USPBlock image="about-us_usp_2" left>
        <T as="h2" html id="aboutUs.usp.2.title" />
        <T as="p" html id="aboutUs.usp.2.description" />
      </USPBlock>

      <USPBlock image="about-us_usp_3">
        <T as="h2" html id="aboutUs.usp.3.title" />
        <T as="p" html id="aboutUs.usp.3.description" />
      </USPBlock>

      <Restriction language={[Language.CS, Language.EN]} only>
        <USPBlock image="about-us_usp_4" left>
          <T as="h2" html id="aboutUs.usp.4.title" />
          <T as="p" html id="aboutUs.usp.4.description" />
        </USPBlock>
      </Restriction>

      <section className={styles.learnMoreSection}>
        <T as="p" data={{ link: generatePath(RouteEnum.STATIC_FAQ) }} html id="aboutUs.learnMore" />
      </section>

      <section className={styles.cards}>
        <div className={styles.card}>
          <T as="h4" id="aboutUs.cards.1.title" />
          <T as="p" id="aboutUs.cards.1.description" />
        </div>
        <div className={styles.card}>
          <T as="h4" id="aboutUs.cards.2.title" />
          <T as="p" id="aboutUs.cards.2.description" />
        </div>
        <div className={styles.card}>
          <T as="h4" id="aboutUs.cards.3.title" />
          <T as="p" id="aboutUs.cards.3.description" />
        </div>
      </section>

      <section className={styles.reasonSection}>
        <StaticImage alt="HoppyGo logo" className={styles.logo} name="sprite-hoppygo-orange-logo-notext" />
        <T as="p" className={styles.statisticsText} html id="aboutUs.claim" />
      </section>

      <section className={styles.teamSection} id="team">
        <T as="h2" className="display" id="aboutUs.team.title" />
        <div className={styles.team}>
          {members.map(it => (
            <Person image={it.image} job={it.job} key={it.id} link={it.link} name={it.name} />
          ))}
        </div>
      </section>
    </PageLayout>
  );
}

interface PersonProps {
  name: string;
  job: string;
  link?: string;
  image: string;
}

function Person({ name, job, link, image }: PersonProps) {
  let Component = 'div';
  const props: React.AnchorHTMLAttributes<HTMLAnchorElement> = {
    className: styles.person,
  };
  if (link) {
    Component = 'a';
    props.href = link;
    props.target = '_blank';
    props.rel = 'noreferrer noopener';
  }
  return (
    <Component {...props}>
      <div className={styles.personText}>
        <h4>{name}</h4>
        <p>{job}</p>
      </div>
      <StaticImage alt={`${name} / ${job}`} loading="lazy" name={image} />
    </Component>
  );
}
